@use 'styles/mixins';
@use 'styles/variables';

.ctaWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 3.5rem 1.7rem;
  @include mixins.media {
    flex-direction: row;
    height: 100%;
    margin: 3rem 6rem;
  }
}

.mediaContainer {
  width: 100%;
  @include mixins.media {
    width: 50%;
    position: sticky;
    top: 10rem;
    height: 100%;
  }

  .media {
    width: 100%;
    display: inline-block;
    order: 0;
    border-radius: 0.2rem;
    @include mixins.media {
      width: 50%;
      display: inline;
    }
    img {
      border-radius: 0.2rem;
    }
  }
}

.textDiv {
  width: 100%;
  padding: 1.6rem;
  align-self: center;
  @include mixins.media {
    width: 50%;
    padding: 3.6rem 6rem;
  }
}

.ctaTitle {
  @include mixins.titleTypography;
  font-size: 2.8rem;
  margin: 1.6rem;
  @include mixins.media {
    font-size: 3.2rem;
    margin: 0;
  }
}

.ctaButton {
  display: inline-block;
  margin-top: 1rem;
  white-space: pre;
}